import logo from './NissenConcrete-Light.svg';
import './App.css';
import { motion } from "framer-motion"
import '@fontsource/roboto';
import '@fontsource/roboto/700.css';
import ReactGA from 'react-ga';
import moment from 'moment';

ReactGA.initialize('G-L8B9D9CKB3');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {

  return (
    <div className="App">
      <header className="App-header">
        <motion.img 
          src={logo} 
          className="App-logo" 
          alt="Nissen Concrete" 
          initial={{opacity: 0, scale: .1}} 
          animate={{opacity: 1, scale: 1}}
          transition={{duration: 2}}
        />
        <motion.p
          initial={{opacity: 0}}
          animate={{y: 0, opacity: 1}}
          transition={{duration: 1, delay: 2.2}}
        >
          COMING SPRING {moment().year()}
        </motion.p>
      </header>
    </div>
  );
}

export default App;
